import axios from 'axios'

export default{
    namespaced: true,
    state: {
        
    },
    getters: {
       
    },
    mutations: {
        
    },
    actions: {

        getMarkSheetTerms () {
            let url = '/api/mark-sheet-terms';
            return axios.get(url);
        },
        
        getReportCardTerms () {
            let url = '/api/report-card-terms';
            return axios.get(url);
        },

        getClassListYears () {
            const url = '/api/class-list-years';
            return axios.get(url);
        },

        downloadASR () {
            const url = '/api/asr';
            return axios({
                method: 'get',
                url: url,
                responseType: 'arraybuffer'
            })
        }
    }
}    