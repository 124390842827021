<template>
    <div style="position:relative; height:380px; ">
        <Bar
            :chart-options="chartOptions"
            :chart-data="chartData"
            :width="width"
            :height="height"
            :plugins="plugins"
        ></Bar>
    </div>
</template>

<script>
import { Bar } from 'vue-chartjs/legacy';
import { Chart as ChartJS, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';

ChartJS.register(Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
    components: { Bar },
    
    props: {
        width: {
            type: Number,
            default: 100,
        },
        height: {
            type: Number,
            default: 60
        },
        chartData: {
            type: Object,
            default: () => {}
        },
    },
    data () {
        return {            
            chartOptions: {
                responsive: true,
                scales: {
                    y: {
                        stacked: true,
                        suggestedMax: 200,
                    },
                    x: {
                        stacked: true,
                        grid:{
                            display: false
                        }
                    }
                },
                
            },
            plugins: [
                
                {
                    afterDraw: function (chartInstance) {
                        // console.log(chartInstance);
                        var ctx = chartInstance.ctx;
                        chartInstance.data.datasets.forEach(function(dataset, i) {
                            var meta = chartInstance.getDatasetMeta(i);
                            if(!meta.hidden){
                                meta.data.forEach(function(element, index){
                                    ctx.fillStyle = 'rgb(0,0,0)';
                                    var fontSize = 16;
                                    var dataString = dataset.data[index].toString();

                                    ctx.textAlign = 'center';
                                    ctx.textBaseLine = 'middle';

                                    var padding = 15;
                                    var position = element.tooltipPosition();
                                    ctx.fillText(dataString, position.x, position.y+(fontSize/2) - padding)
                                })
                            }
                        })
                    }
                }
            ]
        }
    }
}
</script>