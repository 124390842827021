import Vue from 'vue';
import App from './components/layouts/App';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
// import msal from 'vue-msal';

Vue.config.productionTip = false;
// Vue.use(msal, {
//   auth: {
//     clientId: '84a9da45-1ca1-4295-bfb6-8e7be53ebc5e',
//     redirectUri: 'http://localhost:8080',
//     postLogoutRedirectUri: 'http://localhost:8080',    
//   }
// })

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')


