<template>
    <v-expand-transition>
        <v-card
            class="mx-auto"
            width="400"            
            v-show="expand"
            light                        
        >    
            <v-card-title
                class="primary white--text"                
            >
                <v-btn
                    fab
                    small
                    color="primary"
                    dark
                    depressed
                    class="mr-2"
                    @click="previous"
                    v-if="admin"
                >
                    <v-icon>
                        mdi-arrow-left
                    </v-icon>
                </v-btn>
                Select Subject Class
            </v-card-title>
            
            <v-card-text
                style="height: 60vh; overflow-y: scroll;"
                class="px-0"
            >
                <v-list 
                    light              
                >
                    <v-list-item-group v-model="lessonSelected">
                        <v-list-item
                            v-for="(item, i) in teacherLessons"
                            :key="i"
                            @click="setLesson(item)"
                        >
                            <v-list-item-avatar
                                v-if="item.displayIcon"
                            >
                                <v-icon class="primary white--text">
                                    {{ item.icon }}
                                </v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ item.lesson }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-card-text>    
        </v-card>
    </v-expand-transition>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
export default {    
    created: function () {        
        this.initialize();
    },

    data: () => ({        
        lessonSelected: {lesson: '', className: '', subjectCode: '', subjectTitle: ''},
        records: [],
        expand: false,        
    }),

    computed: {
        ...mapGetters({            
            academicTermId: 'app/getAcademicTermId',
            getUser: 'auth/getUser',
            teacherLessons: 'markSheet/getTeacherLessons',
            selectedTeacher: 'markSheet/getSelectedTeacher',
            displayLessons: 'markSheet/getDisplayLessons',
            admin: 'auth/getAdmin',
        }),

        // user () {
        //     return JSON.parse(this.getUser);
        // }, 

    },
    
    watch: {
        displayLessons: {
            handler (val) {
                console.log(`Display Lessons: ${val}`);
            }
        }
    },

    methods: {
        ...mapActions({
           getLessonRecords: 'markSheet/getLessons',
           getStudents: 'markSheet/getStudents',
           getPresetCommentsList: 'markSheet/getPresetCommentsList',
           getTermConfiguration: 'termReports/getTermConfiguration',            
        }),

        ...mapMutations({
            setMarksheetLoading: 'markSheet/setMarkSheetLoading',
            setLessonSelected: 'markSheet/setLessonSelected',
            setTeacherLessons: 'markSheet/setTeacherLessons',
            setTermMarkRecords: 'markSheet/setTermMarkRecords',
            setTotalStudents: 'markSheet/setTotalStudents',
            setPresetComments: 'markSheet/setPresetComments',
            setModal: 'app/setModal',
            setModalTitle: 'app/setModalTitle',
            setModalIcon: 'app/setModalIcon',
            setModalMessage: 'app/setModalMessage',
            setCourseWorkOnly: 'termReports/setCourseWorkOnly',
            setDisplayTeachers: 'markSheet/setDisplayTeachers', 
            setDisplayLessons: 'markSheet/setDisplayLessons',
            setOverlay: 'markSheet/setMarkSheetOverlay',           
        }),

        async initialize () {
            this.setMarksheetLoading(true); 

            let employeeID = this.selectedTeacher ? this.selectedTeacher.id : sessionStorage.getItem('employeeId');
            try {                
                const { data } = await this.getLessonRecords(employeeID);
                
                this.setTeacherLessons([]);               
                
                if(data.length > 0){
                    data.forEach(lessonRecord =>{
                        let subject = lessonRecord.subject_title;
                        this.teacherLessons.push({
                            lesson: lessonRecord.form_class_id + ' ' + subject,
                            className: lessonRecord.form_class_id,                    
                            subjectCode: lessonRecord.subject_id,
                            subjectTitle: subject,
                            formLevel: lessonRecord.form_level,
                            icon: 'mdi-account-group',
                            displayIcon: true,
                        })
                    });
                    this.setTeacherLessons(this.teacherLessons);
                }               
                else{                    
                    this.setModalIcon('warning');
                    this.setModalMessage('No Classes have been assigned');
                    // this.setModal(true);
                    this.teacherLessons.push({
                        lesson: 'No Classes have been assigned'                            
                    })
                }                
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.displayError(error);
            }            
            this.setMarksheetLoading(false);
            setTimeout(() => {
                this.expand = true;
            }, 600) 
        },

        async setLesson(lesson){
            this.expand = false;
            let employeeId = this.selectedTeacher ? this.selectedTeacher.id : sessionStorage.getItem('employeeId')
            this.setDisplayLessons(false);
            setTimeout(() => {
                this.setMarksheetLoading(true);
            }) 
            this.setLessonSelected(lesson);
            this.setTermMarkRecords([]);
            try {
                const promiseStudents = this.getStudents({
                    academicTermId: this.academicTermId, 
                    employeeId: employeeId
                });
                const promisePresetComments = this.getPresetCommentsList();
                const promiseTermConfiguration = this.getTermConfiguration();

                const [
                    { data: { total, data }},
                    { data: presetComments },
                    { data: termConfiguration },
                ] 
                = await Promise.all([
                    promiseStudents, 
                    promisePresetComments,
                    promiseTermConfiguration,
                ]);
                // console.log(data);
                this.updateTermMarkRecords(data);
                this.setTotalStudents(total);
                this.setPresetComments(presetComments);
                if(termConfiguration && termConfiguration.exam_mark === 0){
                    this.setCourseWorkOnly(true);
                }
                else if(!termConfiguration){
                    this.setCourseWorkOnly(false);
                }                 
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.displayError(error);
            }
            this.setOverlay(false);
            this.setMarksheetLoading(false);
        },

        updateTermMarkRecords(records){
            let termMarkRecords = [];
            let count = 0;
            records.forEach(record =>{
                let termMarkRecord = {};
                termMarkRecord.count = ++count;
                termMarkRecord.student_id = record.student_id;
                termMarkRecord.name = record.first_name + ' ' + record.last_name;
                termMarkRecord.subject_id = record.subject_id;
                termMarkRecord.course_mark = record.course_mark;
                termMarkRecord.exam_mark = record.exam_mark;
                termMarkRecord.conduct = record.conduct;
                // termMarkRecord.interest = record.interest;
                termMarkRecord.employee_id = record.employee_id;
                termMarkRecord.academic_term_id = record.academic_term_id;
                termMarkRecord.comment = record.comment;
                termMarkRecord.course_attendance = record.course_attendance;
                termMarkRecord.exam_attendance = record.exam_attendance;
                termMarkRecords.push(termMarkRecord);
            })
             this.setTermMarkRecords(termMarkRecords);
        },        

        displayError(error){
            this.setModalIcon('error');
            this.setModalMessage(error);
            this.setModal(true);
        },

        previous () {
            this.expand = false;
            this.setDisplayLessons(false);
            setTimeout(() => {
                this.setDisplayTeachers(true);
            }, 600)

        }


    }
}
</script>