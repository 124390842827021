<template>
    <td>
        <v-text-field 
            v-model="subjectRecord.mark"            
            @blur="markValidation"
            color="light-blue"
            hide-details              
        >
            <template v-slot:append>
                <v-icon
                    v-if="saved"
                    color="light-blue"
                    x-small
                >
                    mdi-check-all
                </v-icon>
            </template>    
        </v-text-field>
    </td>   
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
export default {
    props: {
        subjectRecord: Object,
        formClassId: String,        
    },

    mounted: function () {
        this.initialize();
    },

    data: () => ({
        oldRecord: {},
        saved: false,
    }),

    methods: {
        ...mapMutations({
            setStudentTermMark: 'markSheetArchive/setStudentTermMark',
        }),

        ...mapActions({
            postStudentTermMark: 'markSheetArchive/postStudentTermMark',
        }),

        initialize () {
            this.oldRecord = {...this.subjectRecord};
        },

        markValidation () {
            let previousMark = this.oldRecord.mark;           
            if(
                this.formClassId && 
                (previousMark || this.subjectRecord.mark) &&
                (previousMark != this.subjectRecord.mark) &&
                (!isNaN(this.subjectRecord.mark)) &&
                (this.subjectRecord.mark >= 0 && this.subjectRecord.mark <= 100)
            ) {
                
                this.setStudentTermMark({
                    student_id: this.subjectRecord.studentId,
                    subject_id: this.subjectRecord.subjectId,
                    mark: this.subjectRecord.mark,
                    test_id: this.subjectRecord.testId,
                    academic_term_id: this.subjectRecord.termId,
                })
                this.saveRecord();               
               
            }
            else{
                let subject = this.subjectRecord.subjectField.split('_');  
               
                if(isNaN(this.subjectRecord.mark)){
                    this.$emit('error', { 
                        type: 'dialog-error', 
                        msg: `${subject[0]} ${subject[1]} mark must be a number between 0-100.`
                    });
                }
                else if(!this.formClassId){
                    this.$emit('error', { 
                        type: 'dialog-error', 
                        msg: `Class Must Be Selected.`
                    });
                    this.subjectRecord.mark = null;
                }
                else if(this.subjectRecord.mark < 0 || this.subjectRecord.mark > 100){
                    this.$emit('error', { 
                        type: 'dialog-error', 
                        msg: `${subject[0]} ${subject[1]} mark must be a number between 0-100.`
                    });
                }
                
            }
            
        },       

        async saveRecord () {
            let subject = this.subjectRecord.subjectField.split('_');                       
            try {
                const response = await this.postStudentTermMark();
                console.log(response);
                this.saved = true;
                this.$emit('notify',`${subject[0]} ${subject[1]} mark saved.`);
                this.oldRecord = {...this.subjectRecord};
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.$emit('error', { 
                    type: 'mark-error', 
                    msg: `${subject[0]} ${subject[1]} mark not saved.`
                });
                this.saved = false;
            }
        }
    }
}
</script>

