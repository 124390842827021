import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import app from './app'
import mainMenu from './mainMenu'
import termReports from './termReports'
import markSheet from './markSheet'
import markSheetArchive from './markSheetArchive'
import printViewReports from './printViewReports'
import timetable from './timetable'
import subjectAssignments from './subjectAssignments'
import students from './students'
import employees from './employees'
import settings from './settings'
import studentAttendance from './studentAttendance';
import common from './common'
import axios from 'axios'

axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_API_URI

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        auth,
        app,
        mainMenu,
        termReports,
        markSheet,
        markSheetArchive,
        printViewReports,
        timetable,
        subjectAssignments,
        students,
        employees,
        settings,
        common,
        studentAttendance,
    }
})