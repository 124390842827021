<template>
    <v-dialog
        v-model="dialog"
        width="500"
    >
        <v-card>
            <v-card-title class="text-h5 primary white--text">
                Promote Students
            </v-card-title>
            <v-card-text class="text-h6 font-weight-regular">
                Are you sure you want to promote students for the period {{ academicYearId }}?
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    depressed
                    @click="promote"
                >
                    Promote Students
                </v-btn>
                <v-btn
                    outlined
                    @click="close"
                >
                    Cancel
                </v-btn>
            </v-card-actions>
            <v-overlay
                :absolute="true"
                :value="overlay"
            >
                <v-progress-circular
                    indeterminate
                    size="64"
                ></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    props: {
        dialogOpen: Boolean,
    },

    data: function () {
        return {
            dialog: this.dialogOpen,
            overlay: false,
        }
    },

    computed: {
        ...mapGetters({
            academicYearId: 'app/getAcademicYear',
        })
    },

    methods: {
        initialize () {
            console.log('promote student initialized');
        },

        ...mapActions({
            promoteStudents: 'settings/promoteStudents',
        }),

        async promote () {
            this.overlay = true;
            try {
                const response = await this.promoteStudents();
                console.log(response);
                this.$emit("snackbar-open", {
                    text: 'Promotion completed.',
                    color: 'primary',
                });
                this.dialog  = false;
            } catch (error) {
                if(error.response){
                    console.log(error.response);
                }
                else{
                    console.log(error);
                }
                this.$emit("snackbar-open", {
                    text: 'An error has occured. Promotion Cancelled.',
                    color: 'red',
                })
            }
            this.overlay = false;
        },

        close () {
            this.dialog = false;
            this.$emit("dialog-close");
        },
        
    }
}
</script>