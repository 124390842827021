<template>
    <v-dialog
        v-model="dialog"
        max-width="700px"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                outlined
                text
                v-bind="attrs"
                v-on="on"
            >
                Upload Students
            </v-btn>
        </template>

        <v-expand-transition>
            <v-card
                v-show="expandFileSelect"
            >
                <v-card-title>
                    Upload Students
                    <v-btn
                        icon
                        class="ml-auto"
                        @click="close"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col>
                                <v-file-input
                                    v-model="file"
                                    show-size
                                    label="Click to select file to upload."
                                    @change="selectFile"
                                ></v-file-input>
                            </v-col>    
                        </v-row>
                        <v-row v-if="file">
                            <v-progress-linear
                                v-model="progress"
                                color="primary"
                                height="25"
                                reactive
                            >
                                <strong>{{ progress }} %</strong>
                            </v-progress-linear>
                        </v-row>

                        <v-alert v-if="message" border="left" :color="messageColor" dark>
                            {{ message }}
                        </v-alert>

                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        outlined
                        class="mr-2"
                        color="primary"
                        @click="close"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        outlined
                        class="mr-2"
                        color="primary"
                        @click="downloadTemplate"
                    >
                        Download Template
                    </v-btn>
                    <v-btn
                        color="primary"
                        dark
                        @click="uploadFile"
                    >Upload File</v-btn>
                </v-card-actions>
                <v-overlay
                    :value="overlayDownload"
                    :absolute="true"
                    color="#fff"
                    opacity="0.9"
                >
                    <v-progress-circular                        
                        indeterminate
                        size="64"
                        color="primary"
                    ></v-progress-circular>
                </v-overlay>
            </v-card>
        </v-expand-transition>

        <v-expand-transition>
            <v-card
                v-show="expandConfirmUpload"
                max-height="95vh"
            >
                <v-card-title>{{ uploadConfirmText }}</v-card-title>
                <v-card-text>
                    <v-data-table
                        v-model="selected"
                        :headers="headers"
                        :items="students"                    
                        height="45vh"
                        fixed-header
                        :items-per-page="itemsPerPage"
                        :loading="loading"
                        :show-select="showSelect"
                    >
                        <template v-slot:item="{ item, headers }">
                            <tr>
                                <td
                                    v-for="(h, index) in headers"
                                    :key="index"
                                >
                                    <span v-if="h.value != 'actions'">
                                        {{ item[h.value] }}
                                    </span>
                                    <span v-else>
                                        <v-icon
                                            v-if="uploadComplete && !item.error"
                                            small
                                            color="green"                            
                                        >
                                            mdi-check-all
                                        </v-icon>
                                        <v-icon
                                            v-if="uploadComplete && item.error"
                                            small
                                            color="red"                            
                                        >
                                            mdi-alert-circle
                                        </v-icon>
                                    </span>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-card-actions
                    class="px-6"
                >
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="!uploadComplete && !deleteRecords"
                        color="primary"
                        outlined
                        small
                        class="mr-2"
                        @click="closeUploadConfirm"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        v-if="deleteRecords"
                        color="primary"
                        outlined
                        small
                        class="mr-2"
                        @click="closeDeleteRecords"
                    >
                        <v-icon left>mdi-arrow-left</v-icon>
                        Back
                    </v-btn>
                    <v-btn
                        v-if="deleteRecords"
                        color="primary"
                        dark
                        small
                        class="mr-2"
                        @click="deleteSelectedRecords"
                    >
                        Delete
                    </v-btn>
                    <v-btn
                        v-if="!uploadComplete && deleteRecords"
                        color="primary"
                        outlined
                        small
                        class="mr-2"
                        @click="selectRecords"
                    >
                        Delete Records
                    </v-btn>
                    <v-btn
                        v-if="!uploadComplete && !deleteRecords"
                        color="primary"
                        dark
                        small
                        @click="uploadConfirm"
                    >
                        Confirm Upload
                    </v-btn>
                    <v-btn
                        v-if="uploadComplete && !deleteRecords"
                        color="primary"
                        outlined
                        small
                        class="mr-2"
                        @click="closeUploadConfirm"
                    >
                        Close
                    </v-btn>
                </v-card-actions>

                <v-overlay
                    :value="overlay"
                    :absolute="true"
                    color="#fff"
                    opacity="0.9"
                >                
                    <v-card                    
                        flat
                        width="400"
                        light
                    >
                        <v-row
                            align-content="center"
                            justify="center"
                        >
                            <v-col 
                                cols="12"
                                class="subtitle-1 text-center"
                            >
                                {{ uploadingText }}
                            </v-col>
                            <v-col 
                                cols="6"
                                v-show="!uploadComplete"
                            >
                                <v-progress-linear
                                    v-model="progress"
                                    height="6"                        
                                    color="primary"
                                    indeterminate
                                    rounded
                                >                        
                                </v-progress-linear>
                            </v-col>
                            
                        </v-row>                    
                    </v-card>
                </v-overlay>

                <v-overlay
                    :value="overlayDeleteConfirm"
                    :absolute="true"
                    color="#fff"
                    opacity="0.9"
                >
                    <v-card 
                        class="pa-2"
                        width="300"
                        light
                    >
                        <v-row
                            align-content="center"
                            justify="center"
                        >
                            <v-col 
                                cols="12"
                                class="subtitle-1 text-center"
                            >
                                {{ deleteRecordsMessage }}
                            </v-col>
                        </v-row>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                outlined
                                class="mr-4"
                                color="primary"
                                small
                                @click="cancelDeleteRecords"
                            >
                                Cancel
                            </v-btn>
                            <v-btn                            
                                color="primary"
                                dark
                                small
                                @click="deleteSelectedRecordsConfirm"                            
                            >
                                Delete
                            </v-btn>
                        </v-card-actions>
                    </v-card>        
                </v-overlay>
            </v-card>
        </v-expand-transition>         
    </v-dialog>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import UploadService from "../services/UploadFileService";
export default {
    data: function () {
        return {
            dialog: false,
            overlay: false,
            expandFileSelect: false,
            file: null,
            progress: 0,
            message: '',
            messageColor: '',
            overlayDownload: false,
            expandConfirmUpload: false,
            uploadConfirmText: '',
            selected: [],
            students: [],
            itemsPerPage: -1,
            loading: false, 
            showSelect: false,
            uploadComplete: false,
            deleteRecords: false,
            overlayDeleteConfirm: false,
            deleteRecordsMessage: '',
            uploadingText: null,
            uploadingMessages: [
                'Please wait...',
                'Working on your request...',
                'Taking a bit long...',
                'Still working...'
            ],
        }
    },

    computed: {
        headers () {
            if(!this.uploadComplete)
            return [
                {text: 'Student ID', value: 'id', width: 65},
                {text: 'Last Name', value: 'last_name'},
                {text: 'First Name', value: 'first_name'},
                {text: 'SEA Reg No.', value: 'sea_number'},
                {text: 'Class', value: 'form_class_id'},                
                {text: 'Gender', value: 'gender'},                
                {text: 'DOB', value: 'date_of_birth'},               
                // {text: 'House', value: 'house_name'},                
                {text: 'Entry Date', value: 'entry_date'},
                {text: '', value: 'actions', width: '50'},                
            ];
            else return [
                {text: 'Student ID', value: 'id', width: 65},
                {text: 'Last Name', value: 'last_name'},
                {text: 'First Name', value: 'first_name'},
                {text: 'SEA Reg No.', value: 'sea_number'},
                {text: 'Class', value: 'form_class_id'},
                {text: 'Gender', value: 'gender'},
                {text: 'DOB', value: 'date_of_birth'},               
                // {text: 'House', value: 'house_name'},                
                {text: 'Entry Date', value: 'entry_date'},  
                {text: '', value: 'actions', width: '50'},
            ]
        }
    },

    watch: {
        dialog (val) {
            console.log(val);
            if(val) {
                this.initialize();
            }
        }
    },

    methods: {
        initialize () {
            console.log("initializing");
            this.expandFileSelect = true;
        },

        ...mapMutations({
            setNewStudents: 'students/setNewStudents',
        }),

        ...mapActions({
            downloadStudentUploadTemplate: 'students/downloadTemplate',
            uploadStudents: 'students/uploadStudents',
        }),

        downloadTemplate () {
            console.log("download template")
            this.overlayDownload = true;            
            this.downloadStudentUploadTemplate()
            .then(response => {
                console.log(response);
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                link.setAttribute('download', 'Registration Upload.xlsx');
                document.body.appendChild(link);
                link.click();
                this.overlayDownload = false;                   
            })
            .catch( error => {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.overlayDownload = false;
                
            })

        },       
        

        selectFile (file) {
            this.progress = 0;
            this.file = file;
            this.message = null;
        },

        uploadFile () {
            if(!this.file) {
                this.messageColor = "red"
                this.message = "Please select a file!";
                return;
            }

            this.message = "";
            this.loading = true;

            UploadService.uploadSpreadsheet(this.file, (event) => {
                this.progress = Math.round((100 * event.loaded) / event.total);
            })
                .then((response) => {
                    this.expandFileSelect = false;
                    this.loading = false;

                    setTimeout(() => {
                        this.expandConfirmUpload = true;
                        this.uploadConfirmText = "Confirm Upload Students"
                    }, 700);
                    
                    this.students = response.data;
                })
                .catch((error) => {
                    if(error.response) console.log(error.response);
                    else console.log(error);
                    this.progress = 0;
                    this.message = "Could not upload the file.";
                    this.messageColor = 'red'
                    this.file = null;
                })

        },

        async uploadConfirm () {
            console.log("upload confirmed");
            this.overlay = true;
            this.uploadComplete = false;
            this.uploadingText = "Uploading Students."
            this.progress = 10;
            let messageCount = this.uploadingMessages.length;
            let index = 0;
            let messageInterval = setInterval(() => {
                if(index < (messageCount - 1)) index++;
                else index = 0;
                this.uploadingText = this.uploadingMessages[index];
                // console.log(`index: ${index}, text: ${this.uploadingMessages[index]}`)
            }, 3000);
            this.setNewStudents(this.students);
            try {
                const { data } = await this.uploadStudents();
                console.log(data);
                this.mapUploadedStudents(data);
                clearInterval(messageInterval);
                this.uploadingText = "Upload Complete.";
                this.uploadComplete = true;
                this.uploadConfirmText = "Upload Complete"
                this.$emit("update-students");
                setTimeout(() => {
                    this.overlay = false
                }, 1000)

            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.overlay = false;
            }
        },

        mapUploadedStudents (data) {
            this.students =  [];
            data.forEach(value => {
                this.students.push(value.record);
            })
        },

        selectRecords () {
            this.showSelect = true;
            this.deleteRecords = true;
            this.uploadConfirmText = "Select Records To Delete"
        },

        cancelDeleteRecords () {
            this.overlayDeleteConfirm = false;
            this.deleteRecordsMessage = '';
        },

        deleteSelectedRecordsConfirm () {
            this.cancelDeleteRecords();
            this.selected.forEach(value => {                
                this.students.splice(this.students.indexOf(value), 1);
            })
            this.selected = [];
        },

        closeUploadConfirm () {
            this.dialog = false;
            this.expandConfirmUpload = false;
            this.expandFileSelect = false;
            this.file = null;
            this.progress = 0;
            this.message = null;
            this.uploadComplete = false;
        },

        close () {
            this.dialog = false;
            this.file = null;
            this.progress = 0;
            this.message = null;
        },
    }
}
</script>