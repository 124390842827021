<template>
    <tr >
        <td>
            <v-select 
                v-model="selectedClass"  
                :items="markRecord.formClasses"
                @change="registerClass"
                hide-details                        
            ></v-select>
        </td>
        <td >
            <v-text-field v-model="markRecord.term"
                class="centered-input
                font-weight-black" 
                :items="terms"                     
                disabled
                hide-details
            ></v-text-field>
        </td>
        <subject-record v-for="record in subjectRecords"
            :key="record.id"
            v-bind:subjectRecord="record"
            v-bind:formClassId="selectedClass"
            v-on:notify="displayNotification"
            v-on:error="errorNotification"         
        ></subject-record>    
    </tr>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import SubjectRecord from './MarkSheetArchiveSheetRecordSubject.vue';

export default {
    components: {
        SubjectRecord
    },

    created: function () {
        this.initialize();
    },

    props: {
        markRecord: Object,
        studentId: Number,       
    },

    data: () => ({
        terms: [1,2,3],            
        fullwidth: true,            
        subjectRecords: [],
        rules: [],            
        ncseSubjects:[
            {id:1, subject:'math_course', subjectId: 33}, 
            {id:2, subject:'math_exam', subjectId: 33}, 
            {id:3, subject:'eng_a_course', subjectId: 18}, 
            {id:4, subject:'eng_a_exam', subjectId: 18}, 
            {id:5, subject:'int_sci_course', subjectId: 30}, 
            {id:6, subject:'int_sci_exam', subjectId: 30}, 
            {id:7, subject:'ss_course', subjectId: 48}, 
            {id:8, subject:'ss_exam', subjectId: 48}, 
            {id:9, subject:'span_course', subjectId: 49}, 
            {id:10, subject:'span_exam', subjectId: 49}, 
            {id:11, subject:'pe_course', subjectId: 41}, 
            {id:12, subject:'pe_exam', subjectId: 41}, 
            {id:13, subject:'vapa_course', subjectId: 53}, 
            {id:14, subject:'vapa_exam', subjectId: 53}, 
            {id:15, subject:'tech_ed_course', subjectId: 51}, 
            {id:16, subject:'tech_ed_exam', subjectId: 51}, 
            {id:17, subject:'ict_course', subjectId: 28},
            {id:18, subject:'ict_exam', subjectId: 28},
        ],
        selectedClass: '',
        classRecord: {form:'', classId:'', termId:''},
    }),

    methods: {
        ...mapMutations({
            setStudentTermDetail: 'markSheetArchive/setStudentTermDetail',
        }),

        ...mapActions({
            registerStudent: 'markSheetArchive/registerStudent',
        }),

        initialize () {
            this.getSubjectRecords();
        },

        getSubjectRecords () {
            let subjectRecord = null;
            this.ncseSubjects.forEach(record =>{
                let subjectField = record.subject;
                subjectRecord = {
                    subjectField: subjectField,
                    mark: this.markRecord[subjectField],
                    studentId: this.studentId,
                    testId: 1,
                    subjectId: record.subjectId,
                    termId: this.markRecord.termId,
                    formClass: this.markRecord.classId,
                    formLevel: this.markRecord.form, 
                }
                if(record.subject.includes('course')){
                    subjectRecord.testId = 2;
                }
                this.subjectRecords.push(subjectRecord)
            });
            this.selectedClass = this.markRecord.classId            
        },
        
        async registerClass () {
            this.setStudentTermDetail({
                student_id: this.studentId,
                academic_term_id: this.markRecord.termId,
                form_class_id: this.selectedClass
            })

            try {
                const response = await this.registerStudent();
                console.log(response);
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
        },

        displayNotification (msg) {            
            this.$emit('notify', msg)
        },

        errorNotification (type, msg) {                     
            this.$emit('error', type, msg);
        },
    }
}
</script>

<style scoped>
    ::v-deep .v-text-field input{
        text-align: center;
    }
</style>