<template>
    <v-row>           
        <v-col>
            <v-card
                class="pa-0 mx-auto"
                width="1000"
                
            >
                <v-card-title>
                    Students
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field 
                        label="search" 
                        v-model="search"
                        append-icon="mdi-magnify"
                        class="px-2"
                        clearable
                    ></v-text-field> 
                </v-card-title>
                <v-data-table :items="students"
                    :headers="headers" 
                    :loading="loading"
                    :search="search"
                    :items-per-page="5"                    
                    multi-sort>
        
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-btn 
                            class="ma-2" 
                            depressed 
                            @click="editMarks(item)" 
                            tile 
                            color="primary"
                            small
                        >
                            <v-icon left >mdi-pencil</v-icon>
                            Edit/View
                        </v-btn>
                    </template>                   
                </v-data-table>
                <v-dialog v-model="dialog" persistent>
                    <v-card>
                        <v-card-title>
                            <span class="headline">{{ formTitle }}</span>
                        </v-card-title>
                                                                    
                        <MarkSheet :editedStudent="editedStudent"/>                            
                        
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="red" text @click="close">Close</v-btn>                    
                        </v-card-actions>
                    </v-card>
                </v-dialog>            
            </v-card>                 
        </v-col>
    </v-row>        
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import MarkSheet from './MarkSheetArchiveSheet.vue';

export default {
    created: function () {
        this.initialize();
    },

    components: {
        MarkSheet,
    },

    data: () => ({
        search: '',
        loading: true,
        dialog: false,
        studentsVisible: true,
        clearSearch: true,
        headers:[
            {text: 'StudentId', value: 'student_id'},
            {text: 'First Name', value: 'first_name'},
            {text: 'Last Name', value: 'last_name'},
            {text: 'Gender', value: 'gender'},
            {
                text: 'Class', 
                value: 'form_class_id',                
            },
            {
                text: '', 
                value: 'actions',
                width: '200',
                sortable: false,
            }
        ],
        students: [],
        inset: true,
        studentSelected: '',
        editedStudent: {
            student_id: '',
            first_name: '',
            last_name: '',
            gender: '',
            form_class_id: '',
        },
        defaultItem: {
            student_id: '',
            first_name: '',
            last_name: '',
            gender: '',
            form_class_id: '',
        },
    }),

    computed: {
        formTitle(){
            return this.editedStudent.first_name + " " + this.editedStudent.last_name;
        }
    },

    methods: {
        ...mapActions({
            getStudents: 'common/getCurrentStudents',
        }),

        ...mapMutations({
            setEditedStudent: 'markSheetArchive/setEditedStudent',
        }),

        async initialize () {
            try {
              const { data } = await this.getStudents();  
              this.students = data;
              this.loading = false;
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
        },

        editMarks(item){ 
            this.dialog = true; 
            this.editedStudent = Object.assign({}, item);
            this.setEditedStudent(Object.assign({}, item));
            this.studentSelected = this.editedStudent.first_name + " " + this.editedStudent.last_name;
        },       

        close () {
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
            })
        },
    }
}
</script>