<template>
    <v-tab-item style="overflow-y:auto; height: 70vh">
        <v-card
            width="400"
            flat
            class="mx-auto mt-3 px-4"
        >        
            <v-form ref="form">
                <v-row
                    v-for="(item, index) in fields"
                    :key="index"

                >
                    <v-text-field
                        v-model="record[item.model]"
                        v-if="item.textField && !item.colGroup"
                        :label="item.label"
                        @blur="update(item.model)"
                        :rules="item.rules"
                    >
                        <template v-slot:append>
                            <v-fade-transition>
                                <v-progress-circular
                                    v-if="item.saveProgress"
                                    size="16"
                                    width="3"
                                    color="info"
                                    indeterminate
                                ></v-progress-circular>
                            </v-fade-transition>

                            <v-fade-transition>    
                                <v-icon
                                    v-if="item.saveSuccess"
                                    small
                                    color="green"                                        
                                >
                                    mdi-check-all
                                </v-icon>
                            </v-fade-transition>

                            <v-fade-transition>    
                                <v-icon
                                    v-if="item.saveError"
                                    small
                                    color="red"                                        
                                >
                                    mdi-alert-circle
                                </v-icon>
                            </v-fade-transition>
                        </template>
                    </v-text-field>

                    <v-text-field
                        v-model="record[item.model]"
                        v-if="item.number && !item.colGroup"
                        :label="item.label"                    
                        @blur="update(item.model)"
                        :onkeypress="isNumber"
                        :hint="item.hint"
                        :rules="item.rules"
                        persistent-hint
                        :maxlength="item.maxlength"
                        :prefix="item.prefix"
                        class="mb-4"
                    >
                        <template v-slot:append>
                            <v-fade-transition>
                                <v-progress-circular
                                    v-if="item.saveProgress"
                                    size="16"
                                    width="3"
                                    color="info"
                                    indeterminate
                                ></v-progress-circular>
                            </v-fade-transition>

                            <v-fade-transition>    
                                <v-icon
                                    v-if="item.saveSuccess"
                                    small
                                    color="green"                                        
                                >
                                    mdi-check-all
                                </v-icon>
                            </v-fade-transition>

                            <v-fade-transition>    
                                <v-icon
                                    v-if="item.saveError"
                                    small
                                    color="red"                                        
                                >
                                    mdi-alert-circle
                                </v-icon>
                            </v-fade-transition>
                        </template>
                    </v-text-field>

                    <v-textarea
                        v-model="record[item.model]"
                        v-if="item.textArea && !item.colGroup"
                        :label="item.label"
                        @blur="update(item.model)"
                        rows="1"
                        auto-grow
                    >
                        <template v-slot:append>
                            <v-fade-transition>
                                <v-progress-circular
                                    v-if="item.saveProgress"
                                    size="16"
                                    width="3"
                                    color="info"
                                    indeterminate
                                ></v-progress-circular>
                            </v-fade-transition>

                            <v-fade-transition>    
                                <v-icon
                                    v-if="item.saveSuccess"
                                    small
                                    color="green"                                        
                                >
                                    mdi-check-all
                                </v-icon>
                            </v-fade-transition>

                            <v-fade-transition>    
                                <v-icon
                                    v-if="item.saveError"
                                    small
                                    color="red"                                        
                                >
                                    mdi-alert-circle
                                </v-icon>
                            </v-fade-transition>
                        </template>
                    </v-textarea>

                    <v-autocomplete
                        v-model="record[item.model]"
                        v-if="item.select && !item.colGroup"
                        :label="item.label"
                        :items="item.items"
                        item-text="title"
                        item-value="id"
                        chips
                        deletable-chips
                        @input="update(item.model)"
                    >
                        <template v-slot:append>
                            <v-fade-transition>
                                <v-progress-circular
                                    v-if="item.saveProgress"
                                    size="16"
                                    width="3"
                                    color="info"
                                    indeterminate
                                ></v-progress-circular>
                            </v-fade-transition>

                            <v-fade-transition>    
                                <v-icon
                                    v-if="item.saveSuccess"
                                    small
                                    color="green"                                        
                                >
                                    mdi-check-all
                                </v-icon>
                            </v-fade-transition>

                            <v-fade-transition>    
                                <v-icon
                                    v-if="item.saveError"
                                    small
                                    color="red"                                        
                                >
                                    mdi-alert-circle
                                </v-icon>
                            </v-fade-transition>
                        </template>
                    </v-autocomplete>

                    <v-container                                
                        v-if="item.colGroup"
                        class="px-0"
                    >
                        <v-row>
                            <v-col 
                                cols="6"
                                v-for="(item, i) in item.colItems"
                                :key="i"
                                class="pl-0"
                            >
                                <v-checkbox                            
                                    v-model="record[item.model]"                       
                                    :label="item.label"
                                    @change="update(item.model)"
                                >
                                    <template v-slot:append>
                                        <v-fade-transition>
                                            <v-progress-circular
                                                v-if="item.saveProgress"
                                                size="16"
                                                width="3"
                                                color="info"
                                                indeterminate
                                            ></v-progress-circular>
                                        </v-fade-transition>

                                        <v-fade-transition>    
                                            <v-icon
                                                v-if="item.saveSuccess"
                                                small
                                                color="green"                                        
                                            >
                                                mdi-check-all
                                            </v-icon>
                                        </v-fade-transition>

                                        <v-fade-transition>    
                                            <v-icon
                                                v-if="item.saveError"
                                                small
                                                color="red"                                        
                                            >
                                                mdi-alert-circle
                                            </v-icon>
                                        </v-fade-transition>
                                    </template>
                                </v-checkbox>
                            </v-col>
                        </v-row>
                    </v-container>    
                </v-row>
            </v-form>
        </v-card>    
    </v-tab-item>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
    export default {
        props: {
            record: {
                type: Object,
                default: function () {
                    return {}
                }
            },

            ethnicGroups: {
                type: Array,
                default: function () {
                    return []
                }
            },

            religiousGroups: {
                type: Array,
                default: function () {
                    return []
                }
            },
        },

        watch: {
            record: {
                handler () {
                    this.setFields();
                }
            },
        },

        computed: {
            isNumber () {
                return 'return (event.keyCode >= 48 && event.keyCode <= 57)'
            },            
        },

        data: () => ({            
            fields: [],
            internetAccess: false,
            schoolFeeding: false,
        }),

        methods: {
            ...mapActions({
                postDataPersonal: 'students/postDataPersonal',
            }),

            ...mapMutations({
                setDataPersonal: 'students/setDataPersonal',
            }),
            
            async update (model) {
                console.log("updating...");
                console.log(model);
                this.saveFeedback(model, 'saveProgress');                
                this.setDataPersonal(this.record);                
                try {
                    const response = await this.postDataPersonal();
                    console.log(response);
                    this.saveFeedback(model, 'saveSuccess');
                } catch (error) {
                    if(error.response) console.log(error.response)
                    else console.log(error);
                    this.saveFeedback(model, 'saveError');
                }
            },

            setFields () {                
                this.fields = [
                    {
                        label: "Home Address",
                        model: "address_home",
                        textArea: true,
                        saveProgress: false,
                        saveSuccess: false,
                        saveError: false,                    
                    },
                    {
                        label: "Town",
                        model: "town",
                        textField: true,
                        saveProgress: false,
                        saveSuccess: false,
                        saveError: false,
                    },
                    {
                        label: "Country of Birth",
                        model: "country_of_birth",
                        textField: true,
                        saveProgress: false,
                        saveSuccess: false,
                        saveError: false,
                    },
                    {
                        label: "Nationality",
                        model: "nationality",
                        textField: true,
                        saveProgress: false,
                        saveSuccess: false,
                        saveError: false,
                    },
                    {
                        label: "Phone (Mobile)",
                        model: "phone_mobile",
                        number: true,
                        prefix: '1868',
                        hint: "Enter 7 Digits only",
                        rules: [ value => (value || '').length == 7 || '7 Digits Needed'],
                        maxlength: 7,
                        saveProgress: false,
                        saveSuccess: false,
                        saveError: false,
                    },
                    {
                        label: "Email",
                        model: "email",
                        textField: true,
                        rules: [value => {
                            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            return pattern.test(value) || 'Invalid e-mail.'
                        }],
                        saveProgress: false,
                        saveSuccess: false,
                        saveError: false,
                    },
                    {
                        label: "Ethnic Group",
                        model: "ethnic_group_id",
                        select: true,
                        items: this.ethnicGroups,
                        saveProgress: false,
                        saveSuccess: false,
                        saveError: false,
                    },
                    {
                        label: "Religion",
                        model: "religion_id",
                        select: true,
                        items: this.religiousGroups,
                        saveProgress: false,
                        saveSuccess: false,
                        saveError: false,
                    },
                    {
                        label: "Previous School",
                        model: "previous_school",
                        textField: true,
                        saveProgress: false,
                        saveSuccess: false,
                        saveError: false,                    
                    },
                    {
                        label: "Blood Group",
                        model: "blood_group",
                        select: true,
                        items: [
                            {
                                id: "O-positive",
                                title: "O-positive",
                            },
                            {
                                id: "O-negative",
                                title: "O-negative",
                            },
                            {
                                id: "A-positive",
                                title: "A-positive",
                            },
                            {
                                id: "A-negative",
                                title: "A-negative",
                            },
                            {
                                id: "B-positive",
                                title: "B-positive",
                            },
                            {
                                id: "AB-negative",
                                title: "AB-negative",
                            },
                            {
                                id: "AB-positive",
                                title: "AB-positive",
                            },
                        ],
                        saveProgress: false,
                        saveSuccess: false,
                        saveError: false,                   
                    },
                    {
                        label: "Device Type",
                        model: "device_type",
                        select: true,
                        items: [
                            {
                                id: "No Device",
                                title: "No Device"
                            },
                            {
                                id: "Phone",
                                title: "Phone"
                            },
                            {
                                id: "Laptop",
                                title: "Laptop"
                            },
                            {
                                id: "Desktop",
                                title: "Desktop"
                            },
                            {
                                id: "Tablet",
                                title: "Tablet"
                            },
                            {
                                id: "Other",
                                title: "Other"
                            },
                        ],
                        saveProgress: false,
                        saveSuccess: false,
                        saveError: false,                                        
                    },
                    {
                        colGroup: true,
                        colItems: [
                            {
                                label: "Internet Access",
                                model: "internet_access",
                                checkbox: true,
                                saveProgress: false,
                                saveSuccess: false,
                                saveError: false,                        
                            },
                            {
                                label: "School Feeding",
                                model: "school_feeding",
                                checkbox: true,
                                colGroup: true,
                                saveProgress: false,
                                saveSuccess: false,
                                saveError: false,                                        
                            },
                        ],                                
                    },
                ]

                this.$nextTick(() => {
                    if(this.$refs.form){
                        this.$refs.form.resetValidation();
                    }                
                })
            },

            saveFeedback (model, status) {
                let saveIcons = this.fields.filter(field => {
                    if(field.colGroup){ 
                        return field;
                    }
                    else if(field.model == model) return field;
                })
                let [ field ] = saveIcons;
                
                if(field.colGroup){
                    [field] = field.colItems.filter(item => {
                        return item.model == model
                    })
                }
                
                Object.keys(field).forEach(key => {
                    if(key == 'saveProgress' || key == 'saveSuccess' || key ==  'saveError')
                    field[key] = false;
                });
                this.$nextTick(() => {
                    field[status] = true;
                })
            }
        }
    }
    
</script>

<style scoped>
    ::v-deep .v-text-field__slot textarea{
        font-size: 16px;
    }
</style>