<template>
    <v-container 
        fluid
        class="pb-0"
    >
        <v-data-table            
            :items="termMarksRecords"
            :height="height"
            :headers="headers"            
            fixed-header
            hide-default-footer
            hide-default-header
        >
            <template v-slot:header>
                <thead>                  
                <tr>
                    <th style="width:80px">Class</th>
                    <th style="width:70px">Term</th>
                    <th 
                        v-for="subjectHeader in subjectHeaders" 
                        :key="subjectHeader.id"
                        colspan="2"
                    >
                        <div>{{ subjectHeader.text}}</div>
                        <div 
                            class="d-flex"
                            style="font-size:0.7rem"
                        >
                            <span class="flex-grow-1 font-weight-light">COURSE</span>
                            <span class="flex-grow-1 font-weight-light">EXAM</span>
                        </div>                    
                    </th>
                </tr>
              </thead>
            </template>
            <template v-slot:item="props">                 
                <mark-sheet-record
                    v-on:notify="displayNotification"
                    v-on:error="errorNotification"
                    v-on:classUpdated="classUpdated"
                    v-bind="{ markRecord: props.item, studentId: editedStudent.student_id }">
                </mark-sheet-record>
            </template>
        </v-data-table>

        <v-snackbar            
            v-model="snackbar"
            :timeout="timeout"
            left
            absolute
            dark
            :color='snackbarColor'
            elevation="10"
            max-width="120px"            
        >
            <v-icon
                left
            >{{ snackbarIcon }}</v-icon>        
            {{ snackText }}        
        </v-snackbar>
        <v-dialog 
            v-model="dialog" 
            persistent 
            max-width="290"
        >
            <v-card>
                <v-card-title class="headline red--text">
                    <v-icon color="red" class="pr-2">mdi-alert-circle-outline</v-icon>
                    Error
                </v-card-title>
                <v-card-text>{{ dialogErrorText }}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="close">Ok</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

         <v-overlay
            :value="overlay"
            opacity="0.8"
            color="grey lighten-5"
            z-index="10"
            absolute                    
        >            
            <v-progress-circular
                indeterminate
                size="64"
                color="primary"
            ></v-progress-circular>                   
        </v-overlay>
    </v-container>    
</template>

<script>
import { mapActions } from 'vuex';
import MarkSheetRecord from './MarkSheetArchiveSheetRecord.vue';

export default {
    created: function () {
        this.initialize();
    },

    components: {
        MarkSheetRecord,
    },   

    props: {
        editedStudent: {
            type: Object,
            default: function () {
                return {};
            }
        } ,              
    },

    watch: {      
        editedStudent: {
            deep: true,
            handler () {
                this.initialize();                
            }
        }  
        
    },

    
    data: () => ({
        headers: [],
        height: '55vh',
        overlay: false,
        dialog: false,
        termMarksRecords: [], 
        subjectHeaders: [          
            {
                id: 2,
                text: 'Maths',                
                value: 'mathematics',
                colspan: 2,
                
            },
            {
                id: 3,
                text: 'English',
                value: 'english',
                colspan: 2,
                
            },
            {
                id: 4,
                text: 'Science',
                value: 'science',
                colspan: 2,
            },
            {
                id: 5,
                text: 'Soc. Studies',
                value: 'socialstudies',
                colspan: 2,
            },
            {
                id: 6,
                text: 'Spanish',
                value: 'spanish',
                colspan: 2,
            },
            {
                id: 7,
                text: 'PE',
                value: 'physicaleducation',
                colspan: 2,
            },
            {
                id: 8,
                text: 'VAPA',
                value: 'vapa',
                colspan: 2,
            },
            {
                id: 9,
                text: 'Tech. Ed.',
                value: 'technologyeducation',
                colspan: 2,
            },
            {
                id: 10,
                text: 'ICT',
                value: 'ict',
                colspan: 2,
            },
            
        ],  
        emptyTermMarksRecord: {
            form: '',
            classId: '',
            termId: '',
            math_exam: '',
            math_course: '',
            eng_a_exam: '',
            eng_a_course: '',
            int_sci_exam: '',
            int_sci_course: '',
            ss_exam: '',
            ss_course: '',
            span_exam: '',
            span_course: '',
            pe_exam: '',
            pe_course: '',
            vapa_exam: '',
            vapa_course: '',
            tech_ed_exam: '',
            tech_ed_course: '',
            ict_exam: '',
            ict_course: '',            
        },
        snackbar: false,
        snackText: '',
        snackbarColor: 'primary',
        snackbarIcon: '',
        timeout: 1000,
        saveSuccess: false,
        currentAcademicPeriod:{
            academicTermId: '',
            term: '',
            academicYearId: ''
        },
        formClasses: [],
        formClassGroups: [],
        dialogErrorText: null,  
    }),

    methods: {
        ...mapActions({
            getStudentTermMarks: 'markSheetArchive/getStudentTermMarks',
            getFormClasses: 'common/getFormClasses',
            getCurrentTerm: 'app/getAcademicTerm',
        }),

        async initialize () {
            this.overlay = true;
            this.termMarksRecords = [];
            try {
                const promiseTermMarks = this.getStudentTermMarks();
                const promiseFormClasses = this.getFormClasses();
                // const promiseCurrentTerm = this.getCurrentTerm();

                const [
                    { data: dataTermMarks }, 
                    { data: dataFormClasses},
                    // { data: dataCurrentTerm }
                    ] = await Promise.all([
                    promiseTermMarks,
                    promiseFormClasses,
                    // promiseCurrentTerm,
                ]);                
                this.formClasses = dataFormClasses;
                this.setTermMarks(dataTermMarks);               
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
            this.overlay = false;
        },        

        setTermMarks (data) {
            let formLevels = [], formRecords = [], formPointer = 1;
            let lastTermId = null, formLevelsTermId = {};

            this.formClasses.forEach(formClass => {
                if(formLevels.indexOf(formClass.form_level) == -1)
                formLevels.push(formClass.form_level);
            })
            
            formRecords = formLevels.map(formLevel => { return {'form': formLevel, 'hasMarks':false}});
            this.groupFormClasses(formLevels);
            
            formLevels.forEach((formLevel, index) => {
                if(formLevel in data) formRecords[index].hasMarks = true;
            })           
            
            Object.keys(data).forEach(key => {
                data[key].forEach(record => {
                    if(record.termId > lastTermId) lastTermId = record.termId;
                })
            })

            lastTermId = Math.round(lastTermId/100)*100;

            formRecords.forEach(formRecord => {
                if(formRecord.hasMarks) formPointer = formRecord.form;
            })

            for(let i = formPointer; i > 0; i--){
                formLevelsTermId[i] = lastTermId;
                lastTermId -= 100;
            }
            
            switch(formPointer){
                case '1':
                    this.pushMarks(data[1]);
                    break;
                
                case '2':                    
                    this.pushForm1Records(formRecords, data, formLevelsTermId[2]);
                    this.pushMarks(data[2]);      
                    break;
                    
                case '3':                   
                    this.pushForm1Records(formRecords, data, formLevelsTermId[1]);
                    this.pushForm2Records(formRecords, data, formLevelsTermId[2]);                   
                    this.pushMarks(data[3]);
                    //console.log(this.termMarksRecords);      
                    break;
            }            
            
        },

        groupFormClasses (formLevels) {
            let formClasses;
            formLevels.forEach(formLevel => {
                formClasses = [];
                this.formClasses.filter(value => {
                    if(value.form_level == formLevel)
                    formClasses.push(value.id);
                })
                this.formClassGroups[formLevel] = formClasses;
            })
        },

        pushMarks (data) {
            data.forEach(record =>{
                //add missing subjects 
                let props = Object.keys(this.emptyTermMarksRecord);
                props.forEach(prop => {                        
                    if(record[prop] === undefined) {
                        record[prop] = '';
                    }                        
                });
                let formLevel = record.form;                    
                record.formClasses = this.formClassGroups[formLevel]
                this.termMarksRecords.push(record)
            });
        },

        pushEmptyRecord (termIdStart, term, formLevel) {           
           let blankRecord = {
            ...this.emptyTermMarksRecord                    
            };                            
            blankRecord.term = term;
            blankRecord.termId = termIdStart + term;
            blankRecord.formClasses = this.formClassGroups[formLevel];
            this.termMarksRecords.push(blankRecord);    
        },

        pushForm1Records (formRecords, data, termStart) {                       
            if(formRecords[0].hasMarks){
                let termsData = [{term:0, isEmpty:true}, {term:1, isEmpty:true}, {term:2, isEmpty:true}, {term:3, isEmpty:true}];                                    
                if(data[1].length == 1){                    
                    let termRecord = data[1][0];
                    termsData[termRecord.term].isEmpty = false;
                    termsData[termRecord.term].data = termRecord;
                    for(let i = 1; i <=3; i++){
                        if(termsData[i].isEmpty) this.pushEmptyRecord(termStart, i, 1);
                        else this.pushMarks([termsData[i].data]);
                    }                
                }
                else if(data[1].length == 2){                    
                    data[1].forEach(termRecord => {                        
                        termsData[termRecord.term].isEmpty = false;
                        termsData[termRecord.term].data = termRecord;
                    });                    
                    for(let i = 1; i <=3; i++){
                        if(termsData[i].isEmpty) this.pushEmptyRecord(termStart, i, 1);
                        else this.pushMarks([termsData[i].data]);
                    }
                }
                else{
                    this.pushMarks(data[1]);
                }
            }
            else{                        
                for(let i = 1; i <=3; i++){
                    this.pushEmptyRecord(termStart, i, 1);
                }               
            }     
        },

        pushForm2Records (formRecords, data, termStart ){            
            if(formRecords[1].hasMarks){ 
                let termsData = [{term:0, isEmpty:true}, {term:1, isEmpty:true}, {term:2, isEmpty:true}, {term:3, isEmpty:true}];                       
                if(data[2].length == 1){
                    let termRecord = data[2][0];
                    termsData[termRecord.term].isEmpty = false;
                    termsData[termRecord.term].data = termRecord;
                    for(let i = 1; i <=3; i++){
                        if(termsData[i].isEmpty) this.pushEmptyRecord(termStart, i, 2);
                        else this.pushMarks([termsData[i].data]);
                    } 
                }
                else if(data[2].length == 2){
                    data[2].forEach(termRecord => {                        
                        termsData[termRecord.term].isEmpty = false;
                        termsData[termRecord.term].data = termRecord;
                    });                    
                    for(let i = 1; i <=3; i++){
                        if(termsData[i].isEmpty) this.pushEmptyRecord(termStart, i, 2);
                        else this.pushMarks([termsData[i].data]);
                    }
                }
                else{
                    this.pushMarks(data[2]);
                }
            }
            else{                        
                for(let i = 1; i <=3; i++){
                    this.pushEmptyRecord(termStart, i, 2);
                }
            }     
        },

        editMarks(item){
            this.selectedIndex = this.students.indexOf(item);
            this.selectedItem = Object.assign({}, item);
            // this.dialog = true;            
        },

        displayNotification(msg){            
            this.snackbar = true;
            this.snackText = msg;
            this.snackbarColor = 'primary';
            this.snackbarIcon = 'mdi-content-save-move';
        },

        errorNotification(e){
            //console.log(`Mark Sheet: ${type}`);                     
            if(e.type == 'dialog-error'){
                this.dialog = true;
                this.dialogErrorText = e.msg
            }
            else{
                this.showSnackBar(e.msg);
            }
        },

        showSnackBar(msg){
            this.snackbar = true;
            this.timeout = 10000
            this.snackText = msg;
            this.snackbarColor = 'red accent-2';
            this.snackbarIcon = 'mdi-alert-circle'
        },
        setColumnWidth(){
            this.subHeaders.forEach(subheader => {
                subheader.width = "60px";
            })
        },
        classUpdated(classRecord){
            //let classUpdated = classRecord.class;
            //let formUpdated = classRecord.form;
            //let termId = classRecord.termId;
            //console.log(`Parent: class updated to ${classUpdated} term ${termId}`);
            console.log(classRecord);
        },
        classSelected(classIsSelected){
            console.log(classIsSelected);
        },
        
        close () {
            console.log('closing dialog...');
            this.dialog = false;

        }
        
    }
}
</script>

<style scoped>
    ::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
        padding: 0 8px;
    }

    /* ::v-deep #term-mark-archive table > tbody > tr > td{
        padding: 0 4px;
    } */
</style>