<template>
    <v-container class="pb-0">
        <v-data-table
            :items="termMarkRecords"
            :loading="loading"
            :headers="headers"
            :height="height"            
            fixed-header           
            class="enter-marks-table"
            disable-pagination
            hide-default-footer                                                 
        >
            <template v-slot:item="props">
                <Row
                    v-bind:studentRecord="props.item"
                />
            </template>
        </v-data-table>
        <v-bottom-sheet
            v-model="presetCommentsSheet"
            inset
            persistent
        >
            <v-sheet
                class="pa-4"
                height="300px"
            >
                <v-card
                    flat
                >
                    <v-card-title>Select Comment</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text style="height: 150px; overflow-y: scroll">
                        <v-list>
                            <v-list-item-group
                                v-model="selected"
                                active-class="green--text"
                                multiple
                            >
                                <template v-for="(comment, index) in presetCommentsList">
                                    <v-list-item :key="comment.id">
                                        <template v-slot:default="{ active }">
                                            <v-list-item-content>
                                                <v-list-item-title v-text="comment.description"></v-list-item-title>
                                                
                                            </v-list-item-content>
                                            <v-list-item-action>                                                
                                                <v-icon
                                                    v-if="!active"
                                                    color="grey lighten-1"
                                                >
                                                    mdi-star-outline
                                                </v-icon>
                                                <v-icon
                                                    v-else
                                                    color="yellow darken-3"
                                                >
                                                    mdi-star
                                                </v-icon>
                                            </v-list-item-action>
                                        </template>
                                    </v-list-item>

                                    <v-divider
                                        v-if="index < commentTemplates.length -1"
                                        :key="index"
                                    ></v-divider>
                                </template>
                            </v-list-item-group>
                        </v-list>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="close"
                        >
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-sheet>
        </v-bottom-sheet>
    </v-container>
</template>

<script>
import Row from './MarkSheetRow';
import { mapGetters, mapMutations } from 'vuex';
export default {
    components: {
        Row,
    },
    data: () =>({
        loading: false,
        height: "52vh",
        pagination: false,
        footer: false,                
        headers: [
            {text: '#', value: 'count', width: '60', sortable: false},
            {text: 'Name', value: 'name', sortable: false,},
            {text: 'Term', value: 'courseMark', align: 'center', width: '90', sortable: false,},            
            {text: 'Exam', value: 'examMark', align: 'center', width: '90', sortable: false,},            
            // {text: 'Conduct', value: 'conduct', align: 'center', width: '90', sortable: false,},
            // {text: 'Interest', value: 'conduct', align: 'center', width: '90', sortable: false,},
            {text: 'Comment', value: 'comment', align: 'center', width: '400', sortable: false,},
            {text: ' ', value: 'actions', align: 'center', sortable: false, width: '40'},                        
        ],
        presetCommentsSheet: false,
        commentTemplates: [],
        selected: [],       
        
    }),    
    watch: {
        presetCommentsSheet: {
            handler(val){
                //console.log(val);
                if(!val) this.setDisplayPresetComment(false);
            }
        },
        presetComments: {
            handler(val){
                //console.log(val);
                this.presetCommentsSheet = val;
            }
        },
        comments: {
            handler(val){
                console.log(val)
                //this.setPresentComments(val);
            }
        },
        selected: {
            handler(val){
                //console.log(val[0]);
                if(val.length > 0){
                    let commentsSelected = '';
                    val.forEach(comment => {
                        commentsSelected +=  this.presetCommentsList[comment].description + ' ';
                        commentsSelected = commentsSelected.substring(0,100);
                    })
                    //console.log(commentsSelected);
                    this.termMarkRecords[this.selectedRecordIndex].comment = commentsSelected;
                    //console.log(this.termMarkRecords[this.selectedRecordIndex]);
                }
                
                //this.setCommentsSelected(val);
            }
        }        
    },    
    computed:{
        ...mapGetters({            
            termMarkRecords: 'markSheet/getTermMarkRecords',
            presetComments: 'markSheet/getDisplayPresetComment',
            presetCommentsList: 'markSheet/getPresetComments',
            selectedRecordIndex: 'markSheet/getSelectedRecordIndex',
            //studentSelected: 'markSheet/getSelectedStudentRecord',            
            //selectedRecordIndex: 'enterMarks/getSelectedRecordIndex',
        }),
    },
    methods: { 
       ...mapMutations({
           setDisplayPresetComment: 'markSheet/setDisplayPresetComment',
           //setCommentsSelected: 'enterMarks/setCommentsSelected',
       }),
       close(){
           this.selected = [];
           this.setDisplayPresetComment(false);
           //this.setSelectedComment();
       },
       setSelectedComment(){
           console.log(this.selectedRecordIndex);
           //let index = this.termMarksRecords.indexOf(this.studentSelected);
           //this.termMarksRecords[index].comments = this.commentTemplates[this.selected[0]]
           //console.log(index);
           //console.log(this.commentTemplates[this.selected[0]].headline);
           //console.log(this.termMarksRecords[this.selectedRecordIndex])
           //this.termMarksRecords[index].comments = this.commentTemplates[this.selected[0]].headline 
       },
       setPresentComments(data){
           console.log(data);
           data.forEach( comment => {
               let template = {};
               if(comment.comment_type_id == 1) template.title = 'Behavioural'
               else template.title = 'Academic'
               template.id = comment.id;
               template.headline = comment.description
               this.commentTemplates.push(template);
           })
       }
    }
}
</script>

<style>
    .v-text-field__slot textarea{
        font-size: 13px;
    }
    .v-data-table > .v-data-table__wrapper > table{
        table-layout: fixed;
    }
    th span{
        display: flex;
    }
</style>