import axios from 'axios'

export default {
    namespaced: true,
    state: {
        attendanceDate: null,
    },
    getters: {
        getAttendanceDate (state) {
            return state.attendanceDate;
        },
    },
    mutations: {
        setAttendanceDate (state, value) {
            state.attendanceDate = value;
        }
    },
    actions: {
        getAttendanceSummaryData ({ getters }) {
            const url = 'api/attendance-report-summary-data'
            return axios.get(url, {
                params: {
                    attendance_date: getters.getAttendanceDate
                }
            })
        },
    }

}