import  axios  from "axios"

export default {
    namespaced: true,

    state: {
        editedStudent: {},
        studentTermDetail: {},
        studentTermMark: {},
    },

    getters: {
        getEditedStudent (state) {
            return state.editedStudent;
        },

        getStudentTermDetail (state) {
            return state.studentTermDetail
        },

        getStudentTermMark (state) {
            return state.studentTermMark
        }
    },

    mutations: {
        setEditedStudent (state, value) {
            state.editedStudent = value
        },

        setStudentTermDetail (state, value) {
            state.studentTermDetail = value
        },

        setStudentTermMark (state, value) {
            state.studentTermMark = value
        }
    },

    actions: {
        getStudentTermMarks ({ getters }) {
            const url = '/api/term-marks/' + getters.getEditedStudent.student_id;
            return axios.get(url);
        },

        registerStudent ({ getters}) {
            const url = '/api/register-student';
            return axios.post(url, getters.getStudentTermDetail);

        },

        postStudentTermMark ({ getters }) {
            const url = '/api/student-term-mark';
            return axios.post(url, getters.getStudentTermMark);
        }
    }

}