import axios from 'axios'

export default {
    namespaced: true,

    actions: {
        getCurrentStudents () {
            let url = '/api/students';
            return axios.get(url);
        },

        getFormClasses(){
            let url = '/api/form-classes'            
            return axios.get(url);
        },
    }
}